* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Encode Sans Expanded", sans-serif;
}

.lobster-font {
  font-family: "Lobster", cursive;
}
